<template>
    <div class="main">

        <div class="container">

            <div class="title">

                <h1>
                    {{ activeLanguage.title }}
                </h1>

            </div>


            <div class="filter-bar-container">

                <div class="filter-bar">
                    <div>
                    <button type="button" class="btn btn-secondary" @click="toggleInvoiceFilter">
                        <i class="filter-icon fa fa-filter fa-lg" aria-hidden="true"></i>
                        {{ activeLanguage.filterBtn }}
                        <i class="fa fa-caret-down caret" aria-hidden="true"></i>
                    </button>

                    <form class="form-inline float-right" @submit.prevent="getInvoices(1)" v-show="!showInvoiceFilter">
                        <input
                            class="form-control mr-sm-2"
                            type="text"
                            :placeholder="activeLanguage.searchText"
                            aria-label="Search"
                            v-model="activeFilters.invoice"
                        >
                        <button class="btn nopadding" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
                    </form>
                    </div>
                </div>
                <transition name="collapse">
                    <InvoicesFilter @clearNum="delete activeFilters.invoice" v-show="showInvoiceFilter" ref="invoiceFilter" @onFiltersApply="onFiltersApply" :activeLanguage="activeLanguage.invoiceFilter" />
                </transition>
            </div>

            <div class="table-bar" v-if="showInvDownloadBtn">
                &nbsp;
                <div class="download_container">

                    <div class="form-row">
                        <div class="form-group col-md-5 col-6">
                            <button @click.prevent="downloadInvById" class="btn">{{ activeLanguage.downlodAllBtn }}</button>
                        </div>
                    </div>

                </div>

            </div>

            <InvoicesTable
                @onInvoiceSelect="onInvoiceSelect"
                :activeLanguage="activeLanguage.invoiceTable"
                :invoices="invoices"
            />


        </div>
    </div>
</template>

<script>
    import InvoicesFilter from '@/components/invoiceView/InvoicesFilter.vue'
    import Pagination from '@/components/common/reusable/Pagination.vue'
    import InvoicesTable from '@/components/invoicesReseller/InvoicesTable.vue'
    import PaginationCount from '@/components/common/reusable/PaginationCount.vue'
    import {mapGetters} from  "vuex"

	export default {
		name: "invoices",
        data() {
			return {
				showInvoiceFilter: false,
				showInvDownloadBtn: false,
                invoices: [],
				activeFilters: {},
				pagData: {
					currentPage: 1,
					maxItems: 0,
				},
				ids: [],
                disablePagination: false
            }
        },
        mounted() {
	        let invoiceFilters = JSON.parse(sessionStorage.getItem('invoiceFilterParams'))

            if (invoiceFilters) {
	            delete invoiceFilters.filter.client_id

	            if (Object.keys(invoiceFilters.filter).length) {
		            this.activeFilters = invoiceFilters.filter
		            this.$refs.invoiceFilter.fillFilterOptions(this.activeFilters)
		            setTimeout(() => this.showInvoiceFilter = true)
	            }

	            setTimeout(() => this.getInvoices(invoiceFilters.page || 1) , 0)
            } else {
	            setTimeout(() => {
		            this.getInvoices()
		            this.showInvoiceFilter = true
                })
            }
        },
        methods: {
	        getInvoices(page = 1) {
		        this.disablePagination = true
		        let params = { page, filter: this.activeFilters }

                billinkAxios
                    .get('app/reseller/invoices', { params })
                    .then(({ data }) => {
                        this.invoices = data.invoices
                        this.pagData.currentPage = data.page
                        this.pagData.maxItems = parseFloat(data.total_invoices)

                        sessionStorage.setItem('invoiceFilterParams', JSON.stringify(params))
                    })
                    .catch(err => console.error(err))
	                .then(() => {
		                this.disablePagination = false
                    })
            },
	        onInvoiceSelect(ids) {
		        this.showInvDownloadBtn = ids.length
		        this.ids = ids
	        },
	        downloadInvById() {
		        let params = {
			        ids: this.ids,
			        filter: this.activeFilters
		        }
		        billinkAxios
			        .get(`app/get/zip-bunch`, { params, responseType: 'blob' })
			        .then((res) => {
				        let file = res.headers['content-disposition'].slice(0, -1).replace('inline; filename="', '').split('.'),
					        link = document.createElement('a');

				        link.href = window.URL.createObjectURL(res.data)
				        link.setAttribute('download', `${file[0]}.${file[1]}`);
				        document.body.appendChild(link);
				        link.click();
				        link.remove();
                    })
			        .catch(err => {
				        console.error(err)
				        if (err.response.status === 400) {
					        EventBus.$emit('showAlertModal', 'Some of files does not exist')
				        }
                    })
	        },
	        onFiltersApply(data) {
	        	this.activeFilters = data
                this.getInvoices()
            },
	        toggleHelpSidebar() {
		        this.$store.dispatch('toggleHelpModal')
            },
	        toggleInvoiceFilter() {
		        this.showInvoiceFilter = !this.showInvoiceFilter
            }
        },
		computed: {
			...mapGetters({
				activeLanguage: 'getInvoicesLanguage'
			})
		},
        components: {
			InvoicesFilter,
	        InvoicesTable,
	        Pagination,
	        PaginationCount
        }
	}
</script>

<style scoped lang="scss">
    .show-xs {
        display: none !important;
    }
    @media (max-width: 420px) {
        .form-inline.float-right {
            float: none !important;
            margin-top: 10px;
        }
    }
    @media (max-width: 768px) {
        .fa.fa-sort {
            display: none;
        }
        .table {
            td, th {
                font-size: 10px;
                padding: 2px 6px;
                vertical-align: middle;
            }
            th {
                padding: 6px;
                &.openstaand,
                &.amount {
                    width: 70px;
                }
            }
            .overflow {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre;
                width: 70px;
            }
        }
    }
    @media (max-width: 480px) {
        .table-bar{
            display: none;
        }
        .download_container {
            width: 100%;
        }
        .show-xs {
            display:  table-row !important;
        }
        .download-collapse, .download_container .form-row {
            width: 100%;
            padding: 0 15px;
            button {
                width: 70%;
                display: block;
                margin: 0 auto;
                padding-right: 0;
                padding-left: 0;
                text-align: center;
            }
            button, select {
                height: 24px !important;
                font-size: 10px;
                padding-top: 0;
                padding-right: 0;
                padding-bottom: 0;
            }
        }
        .custom-control-indicator{
            width: 0.7rem;
            height: 0.7rem;
            left: 35%;
        }
        .fa.fa-download{
            font-size: 14px;
        }
        .table {
            td, th {
                font-size: 10px;
                padding: 2px 4px;
                text-align: center;
                &.amount {
                    width: 60px;
                }
            }
        }
    }
</style>