<template>
    <table class="invoices-table table">
        <thead>
        <tr>
            <td class="table-res">
                <label class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" @change="checkAll = !checkAll">
                    <span class="custom-control-indicator"></span>
                </label>
            </td>
            <th>
                Username
            </th>
            <th class="table-res hidden-xs hidden-sm">
                {{ activeLanguage.invDate }}
            </th>
            <th class="text-center">
                {{ activeLanguage.download}}
            </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="inv in invoices">
            <tr>
                <td class="table-res">
                    <label class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" v-model="inv.checked" @change="invoiceIsSelected(inv)">
                        <span class="custom-control-indicator"></span>
                    </label>
                </td>
                <td>
                    <div class="overflow">
                        {{ inv.name }}
                    </div>
                </td>
                <td class="table-res hidden-xs hidden-sm">{{ inv.created_at }}</td>
                <td class="text-center">
                    <a @click.prevent="downloadInv(inv.id)" href="#" class="download-link"><i class="fa fa-download" aria-hidden="true"></i></a>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>

	export default {
		name: "invoices-table",
		props: {
			activeLanguage: {
				type: Object,
				required: true
			},
			invoices: {
				type: Array,
				required: true
			},
		},
        data() {
			return {
                checkAll: false
            }
        },
		watch: {
			checkAll: function (val) {
				let ids = []

				this.invoices.forEach(inv => {
					inv.checked = val
					if (val) { ids.push(inv.id) }
				})

                this.$emit('onInvoiceSelect', ids)
				this.$forceUpdate()
			}
		},
        methods: {
	        invoiceIsSelected({checked, id}) {
		        let ids = []

		        this.invoices.forEach(order => {
			        if (order.checked) { ids.push(order.id) }
		        })

		        this.$emit('onInvoiceSelect', ids)
	        },
	        downloadInv(id) {
                    let params = {
                        file_id: id
                    }

                    billinkAxios
                        .get(`app/reseller/invoices/download`, { params, responseType: 'blob' })
                        .then((res) => {
                            let file = res.headers['content-disposition'].slice(0, -1).replace('inline; filename="', '').split('.'),
                                link = document.createElement('a');

                            link.href = window.URL.createObjectURL(res.data)
                            link.setAttribute('download', `${file[0]}.${file[1]}`);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        })
                        .catch(err => {
                            console.error(err)
                            if (err.response.status === 400) {
	                            EventBus.$emit('showAlertModal', 'Such File doesn\'t exist')
                            }
                        })
            }
        }
	}
</script>

<style scoped lang="scss">
    .download-collapse{
        width: 510px;
    }
    .invoices-table {
        th {
            vertical-align: middle;
        }
        @media (max-width: 768px) {
            .overflow {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 70px;
            }
            .small-date {
                font-size: 11px;
                display: block !important;
            }
        }
        @media (max-width:480px) {
            th {
                text-align: center;
                font-size: 9px;
                padding: 0.4rem;
            }
            td {
               font-size: 12px;
           }
            .overflow {
                width: 54px;
            }
            .small-date {
                font-size: 8px;
            }
        }
    }
</style>